import moment, { Moment } from 'moment';

export const isValidDate = (value?: Moment, minDate?: Moment, maxDate?: Moment) => {
  if (
    value &&
    minDate &&
    moment(value)
      .set({ hour: 12 })
      ?.isBefore(moment(minDate).set({ hour: 1 }))
  )
    return false;
  if (
    value &&
    maxDate &&
    moment(value)
      .set({ hour: 12 })
      ?.isAfter(moment(maxDate).set({ hour: 23 }))
  )
    return false;

  return true;
};

export const toIsoDate = (time: Date, withTimeZone?: boolean) => {
  if (!withTimeZone) return moment(time).toISOString();

  return moment(time).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
};

import Gallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { isMobile } from 'react-device-detect';
import { Close as CloseIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { IconButton } from '../button';

interface ImageGalleryProps {
  photos: ReactImageGalleryItem[];
  startIndex: number;
}

const GalleryWrapper = styled.div`
  position: absolute;

  & .image-gallery-content {
    display: none;

    &.fullscreen {
      display: grid;
      grid-template-rows: 1fr max-content;
      grid-template-columns: 100%;
      height: 100%;

      & .image-gallery-swipe,
      & .image-gallery-slide-wrapper {
        display: grid;
        height: 100%;

        & .image-gallery-slides {
          display: grid;
          grid-auto-rows: 1fr;
          align-items: center;

          & > .image-gallery-slide {
            align-items: center;
            height: 100%;

            & img {
              position: absolute;
              height: 100%;
            }
          }
        }
      }
    }
  }
`;

const CloseFullScreenButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: max-content;
`;

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  photos,
  ...props
}: ImageGalleryProps) => {
  return (
    <GalleryWrapper>
      <Gallery
        items={photos.map((img: any) => ({ original: img, thumbnail: img }))}
        showPlayButton={false}
        useBrowserFullscreen={false}
        showNav={!isMobile}
        renderFullscreenButton={(onClick, isFullscreen) => (
          <CloseFullScreenButton
            className={`image-gallery-icon image-gallery-fullscreen-button ${
              isFullscreen ? '' : 'Closed'
            }`}
            onClick={onClick}
            iconColor={'--white'}
          >
            <CloseIcon />
          </CloseFullScreenButton>
        )}
        {...props}
      />
    </GalleryWrapper>
  );
};

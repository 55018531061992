import {
  useQuery as useReactQuery,
  QueryFunctionContext,
  QueryFunction,
  UseQueryResult,
} from 'react-query';
import { queryClient } from '../../services';

export const useQuery = <TData>(
  key: string | any[],
  queryFn: QueryFunction<TData>,
  config?: Parameters<typeof useReactQuery>[2],
): UseQueryResult<TData> => {
  const globalBeforeFetch = async (): Promise<any> => {
    return queryClient.getQueryState(key);
  };

  const customQueryFn = async (context: QueryFunctionContext) => {
    const res = await globalBeforeFetch();
    if (res.dataUpdateCount !== 0 && res.status === 'success') return res.data;

    return queryFn(context);
  };

  return useReactQuery<any>(key, customQueryFn, { ...config });
};

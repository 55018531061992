import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './design-system/global.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);

serviceWorkerRegistration.register();

import { useState, useRef } from 'react';
import { Select, SearchableSelect, InputContainer, Typography, Button } from '../../design-system';
import { DashboardAdminForm } from './DashboardAdminForm';
import {
  DashboardWrapper,
  DashboardPopup,
  DashboardHeader,
  DashboardInputs,
  FilterIconButton,
  CloseIconButton,
  DashboardTable,
  DashboardTableHeader,
  DashboardTableRows,
  DashboardTableRow,
  DashboardTableCell,
} from './DashboardComponents';
import { useContainersQuery } from '../../hooks';
import { containersService } from '../../services';
import { containerTypes, containerImageTypes } from '../../const';

export const DashboardAdminTable = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenFormPopup, setIsOpenFormPopup] = useState(false);
  const [containerNumber, setContainerNumber] = useState('');
  const [containerType, setContainerType] = useState('');
  const [containerImageType, setContainerImageType] = useState('all');
  const [clientCode, setClientCode] = useState('');
  const [filters, setFilters] = useState<any>({
    containerType: '',
    containerImageType: 'all',
    clientCode: '',
  });
  const selectedContainer = useRef({});
  const isFiltersChange =
    filters.containerType !== containerType ||
    filters.containerImageType !== containerImageType ||
    filters.clientCode !== clientCode;

  const { data, isSuccess, refetch } = useContainersQuery({
    number: containerNumber,
    status: 'GATED_IN',
    type: filters.containerType,
    clientCode: filters.clientCode,
    containerImageType: filters.containerImageType,
  });

  const handleToggleOpenPopup = () => {
    if (isOpenPopup) {
      setContainerType(filters.containerType);
      setContainerImageType(filters.containerImageType);
      setClientCode(filters.clientCode);
    }

    setIsOpenPopup(!isOpenPopup);
  };

  const handleClientCodeSelect = (option: any) => {
    setClientCode(option.label);
  };

  const handleRowClick = (option: any) => {
    selectedContainer.current = option;
    setIsOpenFormPopup(true);
  };

  const handleApplyFiltersClick = () => {
    const newFilters: any = {
      containerType,
      containerImageType,
      clientCode,
    };

    setFilters(newFilters);
    setIsOpenPopup(!isOpenPopup);
  };

  const handleFormCloseClick = () => {
    setIsOpenFormPopup(false);
  };

  return (
    <DashboardWrapper>
      <DashboardPopup isOpen={isOpenPopup}>
        <DashboardHeader>
          <Typography variant={'textXL'} weight={'bold'}>
            Filters
          </Typography>
          <CloseIconButton onClick={handleToggleOpenPopup} />
        </DashboardHeader>
        <DashboardInputs>
          <Select
            label={'Image Filter'}
            placeholder={'Select Image Filter'}
            options={containerImageTypes}
            value={containerImageType}
            onSelect={setContainerImageType}
          />
          <Select
            label={'Container Type'}
            placeholder={'Select Container Type'}
            options={containerTypes}
            value={containerType}
            onSelect={setContainerType}
            withClearButton
          />
          <SearchableSelect
            label={'Client Code'}
            selectedOptionLabel={clientCode || 'Select Client Code'}
            onSelect={handleClientCodeSelect}
            onClear={handleClientCodeSelect}
            queryKey={'client-code-options'}
            queryFn={() =>
              containersService.clientCodeOptions({
                status: 'GATED_IN',
              })
            }
            selectedAreasEqaul={{ key: 'label', keys: [clientCode] }}
            withClearButton={!!clientCode}
          />
          <Button size={'medium'} onClick={handleApplyFiltersClick} disabled={!isFiltersChange}>
            Apply Filters
          </Button>
        </DashboardInputs>
      </DashboardPopup>
      <DashboardAdminForm
        container={selectedContainer.current}
        isOpen={isOpenFormPopup}
        refetch={refetch}
        handleFormCloseClick={handleFormCloseClick}
      />
      <DashboardHeader>
        <InputContainer
          placeholder={'Container Number'}
          data-size={'medium'}
          defaultValue={containerNumber}
          onChange={(e) => setContainerNumber(e.target.value)}
        />
        <FilterIconButton onClick={handleToggleOpenPopup} />
      </DashboardHeader>
      <DashboardTable>
        <DashboardTableHeader>
          <DashboardTableCell>Container</DashboardTableCell>
          <DashboardTableCell>Type</DashboardTableCell>
          <DashboardTableCell align={'right'}>Code</DashboardTableCell>
        </DashboardTableHeader>
        <DashboardTableRows>
          {isSuccess &&
            data.map((row: any, i: any) => (
              <DashboardTableRow key={i} onClick={() => handleRowClick(row)}>
                <DashboardTableCell>{row.number}</DashboardTableCell>
                <DashboardTableCell>{row.type}</DashboardTableCell>
                <DashboardTableCell align={'right'}>{row.clientCode}</DashboardTableCell>
              </DashboardTableRow>
            ))}
        </DashboardTableRows>
      </DashboardTable>
    </DashboardWrapper>
  );
};

import { User, AccountStatus, BelongsTo } from '../types';

export const initialUser: User = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  accountStatus: AccountStatus.DEACTIVATED,
  belongsTo: BelongsTo.CLIENT,
  yardCompanyEmployee: {
    yardCompanyId: '',
    role: '',
    yardCompany: { legalName: '' },
  },
  yardEmployee: {
    yardId: '',
    role: '',
    yard: { name: '' },
  },
  clientEmployee: {
    clientId: '',
    role: '',
    client: { legalName: '' },
  },
  createdAt: new Date(),
  updatedAt: new Date(),
  type: 'CLIENT_DISPATCHER',
};

import { useEffect } from 'react';

export const useCallbackOnKeyDown = (callback: () => void, key: string) => {
  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key !== key) return;

      callback();
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [callback]);
};

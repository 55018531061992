import { useMutation } from 'react-query';
import { controlBackdropLoading } from '../../services';
import { containersService, removeCache } from '../../services';
import { ImageObjType } from '../../types';

export const useUpdateImagesMutation = ({ refetch }: any) => {
  let images: any;

  return useMutation(
    ({ id, photos = [] }: { id: string; photos: ImageObjType[] }) => {
      controlBackdropLoading(1);

      return containersService.updateImages({ id, photos }).then((res: any) => {
        if (typeof res[0] === 'object') images = res[0].data.data;

        res = res.every((res: any) => !!res);
        if (images && !!res) return images;
        return !!res;
      });
    },
    {
      onSettled: async () => {
        controlBackdropLoading(1);

        removeCache(['container-options']);
        refetch();

        controlBackdropLoading(-2);
      },
    },
  );
};

import styled, { css } from 'styled-components';
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography, TextAlign } from '../../design-system';

export const SectionRightAndLeftPadding = css`
  @media screen and (max-width: 70em) {
    padding-right: var(--spacing-20);
    padding-left: var(--spacing-20);
  }

  @media screen and (max-width: 60em) {
    padding-right: var(--spacing-16);
    padding-left: var(--spacing-16);
  }

  @media screen and (max-width: 50em) {
    padding-right: var(--spacing-12);
    padding-left: var(--spacing-12);
  }

  @media screen and (max-width: 30em) {
    padding-right: var(--spacing-4);
    padding-left: var(--spacing-4);
  }
`;

export const ConstructionSection = styled.section`
  display: grid;
  justify-items: center;
  max-width: 144rem;
  margin: 0 auto;
  margin-top: var(--spacing-24);

  padding-right: var(--spacing-24);
  padding-left: var(--spacing-24);
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);

  ${SectionRightAndLeftPadding}
`;

export const ConstructionSectionIcon = styled(SettingsIcon)`
  font-size: 4.8rem !important;
  color: var(--gray-100);
  animation: rotate 4s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface Text {
  children: string;
  align: TextAlign;
}

const SectionHeadingBase = styled(Typography)`
  margin-top: var(--spacing-5);
`;

export const SectionHeading: React.FC<Text> = ({ children, align = 'center' }: Text) => {
  return (
    <SectionHeadingBase variant={'h4'} align={align} weight={'medium'}>
      {children}
    </SectionHeadingBase>
  );
};

import { Route, Navigate, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AutoSignin } from './AutoSignin';
import { PrivateRoute } from './PrivateRoute';

import { App } from '../App';
import { ErrorBoundary } from '../design-system';
import { ErrorPage, ConstructionPage, SignIn, Dashboard } from '../pages';
import { MESSAGES } from '../const';

export const originalRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        }
        errorElement={
          <ErrorPage
            heading={MESSAGES.ERROR_BOUNDARY.HEADING}
            detail={MESSAGES.ERROR_BOUNDARY.DETAIL}
            navigateTo={-1}
          />
        }
      >
        <Route element={<AutoSignin />}>
          <Route index path={'signin'} element={<SignIn />} />
          <Route path={'dashboard'} element={<PrivateRoute Component={Dashboard} />} />
          <Route path={'*'} element={<Navigate to={'/signin'} replace />} />
        </Route>
      </Route>
      <Route path={'*'} element={<Navigate to={'/signin'} replace />} />
    </>,
  ),
);

const constructionRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index element={<ConstructionPage />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </>,
  ),
);

export const router =
  process.env.REACT_APP_IS_CONSTRUCTION === 'true' ? constructionRouter : originalRouter;

import axios from 'axios';
import { extendContainerValidator } from '../utils';
import { ImageObjType } from '../types';

interface ContainerProps {
  number?: string;
  short?: boolean;
  status?: string;
  type?: string;
  clientCode?: string;
  containerImageType?: string;
  yardId?: string;
  clientId?: string;
}

class ContainersService {
  getAll = (props: any) => axios.get(`/containers`, props);

  options =
    ({ ...props }: ContainerProps) =>
    async () => {
      const { containerImageType = 'all', ...params }: any = {
        notLoading: true,
        ...props,
      };

      if (params.clientId || params.yardId) {
        if (!params.clientId || !params.yardId) return [];
      }

      if (params.number?.length < 3 || !extendContainerValidator(params.number))
        delete params.number;
      if (!params.type) delete params.type;
      if (!params.clientCode) delete params.clientCode;

      const res = await this.getAll({
        params,
      });
      let data = res.data.data.filter((container: any) => {
        if (containerImageType === 'all') return true;
        if (containerImageType === 'hasImages' && container.images.length > 0) return true;
        if (containerImageType === 'noImages' && container.images.length === 0) return true;

        return false;
      });

      data = data.map((container: any) => ({
        id: container.id,
        label: container.number,
        number: container.number,
        type: container.type,
        images: container.images,
        clientCode: container.clientYard.client.code,
      }));

      return data;
    };

  clientCodeOptions =
    ({ ...props }: ContainerProps) =>
    async () => {
      const res = await this.getAll({
        params: {
          ...props,
          notLoading: true,
        },
      });
      let containers = res.data.data;
      if (!containers) return [];

      containers = containers.map((container: any) => ({
        label: container.clientYard.client.code,
        id: container.id,
      }));
      return containers.filter((container: any, i: number) => {
        const index = containers.findIndex((obj: any) => obj.label === container.label);
        return index === i;
      });
    };

  uploadImages = (id: string, photos: ImageObjType[] = []) => {
    if (!photos.length) return true;

    const formData = new FormData();
    for (const i in photos.map((obj) => obj.file)) {
      formData.append(`images`, photos[i].file);
    }

    return axios.patch(`/containers/${id}/upload-images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  deleteImages = (id: string, photos: ImageObjType[] = []) => {
    if (!photos.length) return true;

    return axios.patch(`/containers/${id}/delete-images`, { names: photos.map((obj) => obj.name) });
  };

  updateImages = ({ id, photos = [] }: { id: string; photos: ImageObjType[] }) =>
    Promise.all([
      this.uploadImages(
        id,
        photos.filter((obj) => obj.action === 'upload'),
      ),
      this.deleteImages(
        id,
        photos.filter((obj) => obj.action === 'delete'),
      ),
    ]);
}

export const containersService = new ContainersService();

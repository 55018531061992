import { ScrollRestoration, Outlet } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AppProvider } from './context';
import { queryClient } from './services';
import {
  GlobalThemeProvider,
  MobileHeightControl,
  BackdropLoading,
  Snackbar,
} from './design-system';

export const App = () => {
  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <GlobalThemeProvider>
            <Outlet />
            <ScrollRestoration />
            <MobileHeightControl />
            <BackdropLoading />
            <Snackbar />
          </GlobalThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </AppProvider>
  );
};

const imageTypes = ['.jpeg', '.jpg', '.png', '.webp', 'image/'];

export const getFileType = (text: any) => {
  if (imageTypes.some((type) => text.includes(type))) return 'image';

  if (text.startsWith('data:')) {
    const dataPart = text.slice('data:'.length);
    const contentType = dataPart.split(';')[0];
    const fileExtension = contentType.split('/')[1];
    return fileExtension;
  } else {
    const fileExtension = text.split('.').pop();
    return fileExtension;
  }
};

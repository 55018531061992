import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Input, Button, enqueueSnackbar } from '../../../design-system';
import { AuthHeaderBase, AuthFormBase } from '../AuthComponents';
import { useCallbackOnKeyDown, useSigninMutation } from '../../../hooks';
import { REGEX, INPUT_ERRORS } from '../../../const';

export const SignInHeader = () => {
  return (
    <AuthHeaderBase>
      <Typography variant={'h4'} align={'center'} weight={'bold'}>
        Sign In
      </Typography>
    </AuthHeaderBase>
  );
};

export const SignInForm = () => {
  const navigate = useNavigate();
  const { mutate } = useSigninMutation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const validators = {
    email: !REGEX.EMAIL.test(email) ? INPUT_ERRORS.NOT_VALID.EMAIL : '',
    password: !password,
  };

  const handleSubmitClick = () => {
    if (!Object.values(validators).every((isError) => !isError)) return;

    mutate(
      { email: email, password: password },
      {
        onSuccess: () => navigate('/dashboard'),
        onError: (err: any) => enqueueSnackbar(err?.response?.data.message, 'error'),
      },
    );
  };
  useCallbackOnKeyDown(handleSubmitClick, 'Enter');

  return (
    <AuthFormBase>
      <Input
        defaultValue={email}
        onChange={(e) => setEmail(e.target.value)}
        label={'Email'}
        placeholder={'Your Email'}
        type={'email'}
        data-size={'large'}
        withoutSpace
        error={!!email && !!validators.email}
        helperText={!!email && validators.email}
      />
      <Input
        defaultValue={password}
        onChange={(e) => setPassword(e.target.value)}
        label={'Password'}
        placeholder={'Your Password'}
        type={'password'}
        data-size={'large'}
        withoutSpace
      />
      <Button
        data-color={'primary'}
        size={'large'}
        onClick={handleSubmitClick}
        disabled={!Object.values(validators).every((isError) => !isError)}
      >
        Sign In
      </Button>
    </AuthFormBase>
  );
};

import { getFileType } from './get-file-type';

export const filesFormatValidator = (files = {}, format = 'File') => {
  if (
    Object.values(files).every(
      (file: any) => getFileType(file.name).toLocaleUpperCase() === format.toLocaleUpperCase(),
    )
  )
    return;

  return Object.values(files)
    .filter(
      (file: any) => getFileType(file.name).toLocaleUpperCase() !== format.toLocaleUpperCase(),
    )
    .map((file: any) => `"${file.name}" format is not ${format}`)
    .join('\n');
};

import imageCompression from 'browser-image-compression';
import { enqueueSnackbar } from '../snackbar';
import { filesSizeValidator, getFileType } from '../../utils';
import { controlBackdropLoading } from '../../services';

const blobToFile = (blob: Blob, fileName: string) => {
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });

  return file;
};

export const readFiles = async (e: any, onReaded: (readedFiles: any[], files: any[]) => void) => {
  const files = e.target.files;
  let compressedFiles: any[] = [];
  const readedFiles: any = [];
  controlBackdropLoading(1);

  compressedFiles = await Promise.all(
    Object.values(files).map((file: any) => {
      return new Promise((res) => {
        const isImage = getFileType(file.name) === 'image';
        const quality = 0.2 / (file.size / (1024 * 1024));

        if (!isImage) return res(file);

        imageCompression(file, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 640,
          useWebWorker: true,
          maxIteration: 20,
          alwaysKeepResolution: true,
          initialQuality: quality,
        }).then((compressedFile) => res(blobToFile(compressedFile, file.name)));
      });
    }),
  );

  for (let i = 0; i < compressedFiles.length; i++) {
    const file = compressedFiles[i];

    const reader = new FileReader();
    reader.onload = () => {
      readedFiles.push(reader.result);

      if (compressedFiles.length - readedFiles.length !== 0) return;
      return onReaded && onReaded(readedFiles, compressedFiles);
    };
    reader.readAsDataURL(file);
  }
  controlBackdropLoading(-1);
};

export const inputFilesValidators = (readedFiles: any[], files: any[]) => {
  if (readedFiles.length > 10) {
    enqueueSnackbar('Max Upload Files is 10', 'warning');
    return false;
  }
  if (filesSizeValidator(files)) {
    enqueueSnackbar(filesSizeValidator(files), 'warning');
    return false;
  }

  return true;
};

export const getMultipleValue = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
): string[] => {
  let value: string | string[] = e.target.value;
  value = value.split(',');
  const isNewValue = value.length > 1 ? !value[value.length - 1].trim() : false;
  value = value.map((val) => val.trim()).filter((val) => !!val);
  e.target.value = value.join(',') + (isNewValue ? ',' : '');

  return value;
};

import { REGEX } from '../const';

export const containerValidator = (str: any) => REGEX.CONTAINER_NUMBER.test(str);

export const extendContainerValidator = (value: any = '') => {
  const lengthDifference = 11 - value.length;
  if (lengthDifference <= 0) return value;
  let finalValue = value;
  const count = {
    digits: 0,
    letters: 0,
  };
  for (const char of value) {
    const code = char.charCodeAt(0);
    if (code >= 48 && code <= 57) {
      count.digits++;
    }

    if (code >= 65 && code <= 90) {
      count.letters++;
    }
  }

  const digitsLengthDifference = 7 - count.digits;
  const lettersLengthDifference = 4 - count.letters;
  for (let i = 0; i < lettersLengthDifference; i++) {
    if (digitsLengthDifference === 7) finalValue = finalValue + 'Z';
    if (digitsLengthDifference !== 7) finalValue = 'Z' + finalValue;
  }
  for (let i = 0; i < digitsLengthDifference; i++) {
    finalValue += 1;
  }

  return containerValidator(finalValue);
};

import styled from 'styled-components';
import { Paper } from '../../design-system';

const AuthWrapperBase = styled.div`
  display: grid;
  padding: 10rem 0;
  min-height: 100%;

  @media (max-width: 600px) {
    padding: 0;
  }
`;

const AuthPaper = styled(Paper)`
  &.MuiPaper-root {
    padding: 4.8rem 6rem;
    margin: auto;
  }

  @media (max-width: 600px) {
    &.MuiPaper-root {
      padding: var(--spacing-6);
    }
  }
`;

interface AuthWrapperProps {
  children: React.ReactNode;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }: AuthWrapperProps) => {
  return (
    <AuthWrapperBase>
      <AuthPaper>{children}</AuthPaper>
    </AuthWrapperBase>
  );
};

export const AuthHeaderBase = styled.div`
  display: grid;
  grid-row-gap: 0.8rem;
`;

export const AuthHeaderSubTitle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.6rem;
  justify-content: center;
`;

export const AuthFormBase = styled.div`
  display: grid;
  grid-row-gap: 2.4rem;

  margin-top: 4rem;
`;

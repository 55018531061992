import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LogoutOutlined as LogoutOutlinedIcon } from '@mui/icons-material';
import { Logo } from '../logo';
import { IconButton } from '../button';
import { enqueueSnackbar } from '../snackbar';
import { useLogoutMutation } from '../../hooks';
import { MESSAGES } from '../../const';

const AppbarBase = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 0.4rem;
  align-items: center;

  width: 100%;
  height: 6.8rem;

  background-color: var(--gray-90);
  border-bottom: 0.1rem solid var(--gray-50);
  padding: 0 2.4rem;
`;

export const Appbar = () => {
  const navigate = useNavigate();
  const { mutate } = useLogoutMutation();

  const handleLogoutClick = async () => {
    await mutate(undefined, {
      onSuccess: () => {
        enqueueSnackbar(MESSAGES.AUTH.SUCCESS.LOG_OUT, 'success');
        navigate('/signin');
      },
    });
  };

  return (
    <AppbarBase>
      <Logo />
      <IconButton iconColor={'--white'} onClick={handleLogoutClick}>
        <LogoutOutlinedIcon />
      </IconButton>
    </AppbarBase>
  );
};

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&#backdrop-loading': {
            opacity: '0 !important',
            pointerEvents: 'none',
            zIndex: 7777777,
          },
          '&#backdrop-loading.loading': {
            opacity: '1 !important',
            pointerEvents: 'all',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: 'var(--white)',
        },
      },
    },
  },
});

export const BackdropLoading = () => {
  return (
    <ThemeProvider theme={theme}>
      <Backdrop id={'backdrop-loading'} open={true}>
        <CircularProgress />
      </Backdrop>
    </ThemeProvider>
  );
};

import styled, { css } from 'styled-components';
import { Typography, TextAlign } from '../../design-system';

export const SectionRightAndLeftPadding = css`
  @media screen and (max-width: 70em) {
    padding-right: var(--spacing-20);
    padding-left: var(--spacing-20);
  }

  @media screen and (max-width: 60em) {
    padding-right: var(--spacing-16);
    padding-left: var(--spacing-16);
  }

  @media screen and (max-width: 50em) {
    padding-right: var(--spacing-12);
    padding-left: var(--spacing-12);
  }

  @media screen and (max-width: 30em) {
    padding-right: var(--spacing-4);
    padding-left: var(--spacing-4);
  }
`;

export const ErrorSection = styled.section`
  display: grid;
  max-width: 144rem;
  margin: 0 auto;
  margin-top: var(--spacing-24);

  padding-right: var(--spacing-24);
  padding-left: var(--spacing-24);
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);

  ${SectionRightAndLeftPadding}
`;

interface Text {
  children: string;
  align: TextAlign;
}

const SectionNameBase = styled(Typography)`
  color: var(--primary-700);
  margin-bottom: var(--spacing-3);
`;

export const SectionName: React.FC<Text> = ({ children, align = 'center' }: Text) => {
  return (
    <SectionNameBase variant={'textM'} align={align} weight={'semibold'}>
      {children}
    </SectionNameBase>
  );
};

const SectionHeadingBase = styled(Typography)`
  margin-bottom: var(--spacing-5);
`;

export const SectionHeading: React.FC<Text> = ({ children, align = 'center' }: Text) => {
  return (
    <SectionHeadingBase variant={'h4'} align={align} weight={'medium'}>
      {children}
    </SectionHeadingBase>
  );
};

const SectionSubHeadingBase = styled(Typography)`
  margin-bottom: var(--spacing-16);
  color: var(--gray-600);
`;

export const SectionSubHeading: React.FC<Text> = ({ children, align = 'center' }: Text) => {
  return (
    <SectionSubHeadingBase variant={'textXL'} align={align} weight={'normal'}>
      {children}
    </SectionSubHeadingBase>
  );
};

import { useState, useRef, useCallback } from 'react';

export const useStateDebounce = (defaultValue: any, delay = 1000, withLoading = false) => {
  const [, setUpdate] = useState(0);
  const state = useRef(defaultValue);
  const loading = useRef(false);
  const timer = useRef<any>();

  const setValueDebounced = useCallback((val: any, isForce?: boolean) => {
    clearTimeout(timer.current);

    if (isForce || delay === 0) {
      state.current = val;
      setUpdate(Math.random());
      return;
    }

    if (withLoading && !loading.current) {
      loading.current = true;
      setUpdate(Math.random());
    }

    timer.current = setTimeout(() => {
      state.current = val;
      if (withLoading) loading.current = false;

      setUpdate(Math.random());
    }, delay);
  }, []);

  return [state.current, setValueDebounced, loading.current];
};

const stateGenerateKey = (inputText: any) => {
  const step1Text = inputText.replace('set', '');
  const step2Text = step1Text.charAt(0).toLowerCase() + step1Text.slice(1);

  return step2Text;
};

export const useMultipleStateDebounce = (defaultValue: any, delay = 1000, withLoading = false) => {
  const [state, setValueDebounced, loading] = useStateDebounce(defaultValue, delay, withLoading);
  const stateRef = useRef<any>(defaultValue);

  const handleSetValueDebounced = (val: any, isForce?: boolean) => {
    stateRef.current = { ...stateRef.current, ...val };
    setValueDebounced(stateRef.current, isForce);
  };

  const handleCustomSetValueDebounced = new Proxy(handleSetValueDebounced, {
    get(target, property) {
      return (val: any, force: any) => target({ [stateGenerateKey(property)]: val }, force);
    },
  });

  return [state, handleCustomSetValueDebounced, loading];
};

import styled, { css } from 'styled-components';
import { Typography } from '../typography';

interface EmptyFilesMessageProps {
  gridColumn?: string;
}

export const EmptyFilesMessage = styled(Typography)<EmptyFilesMessageProps>`
  padding: 2.8rem;

  ${(props) =>
    props.gridColumn &&
    css`
      grid-column: ${props.gridColumn};
    `}
`;

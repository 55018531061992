import { useNavigate, To } from 'react-router-dom';
import { Button } from '../../design-system';
import { ErrorSection, SectionName, SectionHeading, SectionSubHeading } from './ErrorComponents';

interface ErrorPageProps {
  heading: string;
  detail: string;
  navigateTo: string | number;
  resetError?: () => void;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  heading,
  detail,
  navigateTo,
  resetError,
}: ErrorPageProps) => {
  const navigate = useNavigate();

  const handleTakeBack = () => {
    resetError && resetError();
    navigate(navigateTo as To);
  };

  return (
    <>
      <ErrorSection>
        <SectionName align={'center'}>Oops... </SectionName>
        <SectionHeading align={'center'}>{heading}</SectionHeading>
        <SectionSubHeading align={'center'}>{detail}</SectionSubHeading>
        <Button variant={'contained'} size={'large'} justify={'center'} onClick={handleTakeBack}>
          Take me back
        </Button>
      </ErrorSection>
    </>
  );
};

import { Appbar } from '../../design-system';
import { DashboardBase } from './DashboardComponents';
import { DashboardAdminTable } from './DashboardAdminTable';
import { DashboardClientForm } from './DashboardClientForm';
import { useStore } from '../../context';
import { showToRoles } from '../../utils';

export const Dashboard = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <DashboardBase>
      <Appbar />
      {showToRoles('admin', user) && <DashboardAdminTable />}
      {showToRoles('client', user) && <DashboardClientForm />}
    </DashboardBase>
  );
};

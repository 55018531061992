import { AuthWrapper } from '../AuthComponents';
import { SignInHeader, SignInForm } from './SignInComponents';

export const SignIn = () => {
  return (
    <AuthWrapper>
      <SignInHeader />
      <SignInForm />
    </AuthWrapper>
  );
};

import { Input, InputBaseProps } from './Input';

export const InputContainer: React.FC<InputBaseProps> = ({
  onChange,
  ...props
}: InputBaseProps) => {
  const handleChangeFormat = (e?: any, text = '') => {
    text = text || e?.target.value || '';

    text = text.toUpperCase();
    if (text.length > 11) text = text.slice(0, 11);

    return text;
  };

  const handleOnChange = (e: any) => {
    const caretPosition = e.target.selectionStart;

    if (props.multiline) {
      e.target.value = e.target.value
        .split('\n')
        .map((text: string) => handleChangeFormat(null, text))
        .join('\n');
    } else {
      e.target.value = handleChangeFormat(e);
    }

    e.target.setSelectionRange(caretPosition, caretPosition);

    onChange && onChange(e);
  };

  return <Input onChange={handleOnChange} withoutSpace {...props} />;
};

import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import validator from 'validator';
import {
  Typography,
  SearchableSelect,
  Button,
  Input,
  DatePicker,
  getMultipleValue,
} from '../../design-system';
import { DashboardWrapper, DashboardInputs, DashbaordFormFooter } from './DashboardComponents';
import { useStore } from '../../context';
import { useAddGateOutMutation } from '../../hooks';
import { containersService, clientYardService } from '../../services';
import { REGEX, INPUT_ERRORS } from '../../const';

export const DashboardClientForm = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const { mutate } = useAddGateOutMutation();
  const [yardName, setYardName] = useState('');
  const yardId = useRef('');
  const clientId = useRef(user.clientEmployee.clientId);
  const [containerNumber, setContainerNumber] = useState<any[]>([]);
  const [containerId, setContainerId] = useState<any[]>([]);
  const [validFrom, setValidFrom] = useState<any>();
  const [validTo, setValidTo] = useState<any>();
  const [licensePlateNumber, setLicensePlateNumber] = useState<string[]>([]);
  const [comment, setComment] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const [containerQueryKey, setContainerQueryKey] = useState(Math.random());
  const [updateKey, setUpdate] = useState<any>(Math.random());

  const validators: any = {
    comment: comment
      ? !validator.isLength(comment, { min: 10, max: 200 })
        ? INPUT_ERRORS.NOT_VALID.COMMENT_LENGTH
        : ''
      : '',
  };

  useEffect(() => {
    if (!yardName) return;

    setContainerNumber([]);
    setContainerId([]);
  }, [yardName]);

  const handleContainerSelect = (option: any) => {
    if (containerId.includes(option.id)) {
      const i = containerId.indexOf(option.id);
      setContainerNumber(containerNumber.filter((_, n) => n !== i));
      setContainerId(containerId.filter((_, n) => n !== i));
    } else {
      setContainerNumber([option.value, ...containerNumber]);
      setContainerId([option.id, ...containerId]);
    }
  };

  const handleYardSelect = (obj: any) => {
    yardId.current = obj.id;
    setYardName(obj.label);
  };

  const handleSubmitClick = () => {
    setIsSubmitClicked(true);
    setUpdate(Math.random());

    const formProps: any = {
      yardName,
      yardId: yardId.current,
      clientId: clientId.current,
      containerNumber,
      containerId,
      validFrom,
      validTo,
      transport: 'ground',
      licensePlateNumber,
      comment,
    };

    const props: any = {
      yardName: formProps.yardName,
      containerNumber: !!formProps.containerNumber,
      licensePlateNumber: !!formProps.licensePlateNumber.length,
    };

    const validate: any = {};

    ['containerNumber', 'licensePlateNumber'].forEach((key) => {
      formProps[key].forEach((val: any, i: any) => {
        if (key === 'licensePlateNumber') {
          validate['licensePlateNumber-' + (i + 1)] = REGEX.LICENSE_PLATE_NUMBER.test(val) && !!val;
          validate['licensePlateNumberLength-' + (i + 1)] =
            validator.isLength(val, {
              min: 4,
              max: 10,
            }) && !!val;
          return;
        }

        props[key + '-' + (i + 1)] = val;
      });
    });
    const errors: any = {
      validFrom: moment(formProps.validFrom).set('hour', 23).isAfter(moment().set('hour', 0)),
      validTo: moment(formProps.validTo)
        .set('hour', 23)
        .isAfter(moment(formProps.validFrom).set('hour', 0)),
      hasValidFrom: !!formProps.validFrom,
      hasValidTo: !!formProps.validTo,
    };

    if (
      [
        ...Object.values(props),
        ...Object.values(validate),
        ...Object.values(errors),
        ...Object.values(validators).map((err) => !err),
      ].some((val) => !val)
    ) {
      return;
    }

    mutate(formProps, {
      onSuccess: () => {
        setContainerNumber([]);
        setContainerId([]);

        setLicensePlateNumber([]);
        setValidFrom(null);
        setValidTo(null);
        setComment('');

        setIsSubmitClicked(false);
        setUpdate(Math.random());
        setContainerQueryKey(Math.random());
      },
    });
  };

  return (
    <DashboardWrapper id={'dashboard'}>
      <Typography variant={'textXL'} weight={'bold'}>
        Gate Out Request
      </Typography>
      <DashboardInputs>
        <SearchableSelect
          selectedOptionLabel={yardName}
          label={'Yard*'}
          placeholder={'Select Yard'}
          onSelect={handleYardSelect}
          queryFn={() => clientYardService.assignedYards({ id: clientId.current }, user)}
          queryKey={['yards-short', 'assigned-yards' + clientId.current]}
          selectedAreasEqaul={{ key: 'id', keys: [yardId.current] }}
          error={isSubmitClicked && !yardName}
          helperText={isSubmitClicked && !yardName && INPUT_ERRORS.REQUIRED.YARD_NAME}
        />
        <SearchableSelect
          label={`Container Number`}
          selectedOptionLabel={containerNumber.join(', ') || 'Select Container Number'}
          onSelect={handleContainerSelect}
          queryKey={['container-short', 'Container Number' + containerQueryKey + `${yardName}`]}
          queryFn={(number) =>
            containersService.options({
              yardId: yardId.current,
              clientId: clientId.current,
              number,
              status: 'GATED_IN',
            })
          }
          selectedAreasEqaul={{ key: 'id', keys: containerId }}
          inputValueKey={(inputValue: string) => (inputValue.length < 3 ? '' : inputValue)}
        />
        <Input
          key={updateKey + 1}
          label={`License Plate Number*`}
          placeholder={'10Z234EB,20Z234EC,30Z334EC'}
          data-size={'medium'}
          defaultValue={licensePlateNumber.join(',')}
          onChange={(e) => {
            const value = getMultipleValue(e);
            setLicensePlateNumber(value);
          }}
          error={
            (isSubmitClicked && !licensePlateNumber.length) ||
            (!!licensePlateNumber.length &&
              (licensePlateNumber.some(
                (val: any) => !validator.isLength(val, { min: 4, max: 10 }),
              ) ||
                licensePlateNumber.some((val: any) => !REGEX.LICENSE_PLATE_NUMBER.test(val))))
          }
          helperText={
            isSubmitClicked && !licensePlateNumber.length
              ? INPUT_ERRORS.REQUIRED.LICENSE_PLATE_NUMBER
              : licensePlateNumber.length &&
                licensePlateNumber.some((val: any) => !validator.isLength(val, { min: 4, max: 10 }))
              ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER_LENGTH
              : licensePlateNumber.some((val: any) => !REGEX.LICENSE_PLATE_NUMBER.test(val))
              ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER
              : ''
          }
        />
        <DatePicker
          key={updateKey + 2}
          label={'From*'}
          data-size={'medium'}
          defaultValue={validFrom}
          onChange={setValidFrom}
          minDate={moment()}
          maxDate={validTo ? moment(validTo) : null}
          error={isSubmitClicked && !validFrom}
          helperText={isSubmitClicked && !validFrom && INPUT_ERRORS.REQUIRED.DATE}
        />
        <DatePicker
          key={updateKey + 3}
          label={'To*'}
          data-size={'medium'}
          defaultValue={validTo}
          onChange={setValidTo}
          minDate={validFrom ? moment(validFrom) : moment()}
          disabled={!validFrom}
          error={isSubmitClicked && !validTo}
          helperText={isSubmitClicked && !validTo && INPUT_ERRORS.REQUIRED.DATE}
        />
        <Input
          key={updateKey + 4}
          data-size={'medium'}
          label={'Comment'}
          defaultValue={comment}
          onChange={(e) => setComment(e.target.value)}
          error={!!validators.comment}
          helperText={validators.comment}
        />
      </DashboardInputs>
      <DashbaordFormFooter>
        <Button size={'medium'} onClick={handleSubmitClick}>
          Gate Out
        </Button>
      </DashbaordFormFooter>
    </DashboardWrapper>
  );
};

import axios from 'axios';
import Cookies from 'js-cookie';
import { removeCache } from './index';

interface SignInProps {
  email?: string;
  password?: string;
}

class AuthService {
  signin = (props?: SignInProps) =>
    axios.post(`/auth/login`, {
      email: props?.email,
      password: props?.password,
    });

  logout = () => Promise.resolve(true);

  logoutOnError = () => {
    Cookies.remove('jwt');
    Cookies.remove('user');
    removeCache('*');
    window.location.href = '/signin';
  };
}

export const authService = new AuthService();

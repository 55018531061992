import { ClearRounded as ClearRoundedIcon, ZoomIn as ZoomInIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { Button } from '../button';

export const ImageGalleryCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-gap: var(--spacing-6);
  overflow: overlay;

  @media (min-width: 601px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 901px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Image = styled.div<{ img: any }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  transition: opacity 0.3s;
  cursor: pointer;

  background-image: url(${(props) => props.img});
  background-color: var(--gray-100);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const ImageZoomIcon = styled(ZoomInIcon)`
  width: 75%;
  height: 75%;
  color: var(--gray-70);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImageWrapper = styled.div`
  padding-bottom: calc(9 / 16 * 100%);
  position: relative;
  overflow: hidden;

  &:hover {
    & ${Image} {
      opacity: 0.5;
    }
    & ${ImageZoomIcon} {
      opacity: 1;
    }
  }
`;

const ImageDeleteButtonBase = styled(Button)`
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  padding: 0.8rem;

  position: absolute;
  top: 0rem;
  right: 0rem;
  border-top-right-radius: 0.6rem;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;

  &:focus {
    box-shadow: none;
  }
`;

interface ImageDeleteButtonProps {
  onClick: (e: any) => void;
}

const ImageDeleteButton: React.FC<ImageDeleteButtonProps> = ({
  onClick,
}: ImageDeleteButtonProps) => {
  return (
    <ImageDeleteButtonBase onClick={onClick} size={'small'} data-color={'destructive'}>
      <ClearRoundedIcon fontSize={'large'} />
    </ImageDeleteButtonBase>
  );
};

interface ImageGalleryCardProps {
  img: any;
  handleZoomClick: () => void;
  handleDeleteImageClick: () => void;
  hideDeleteButton?: boolean;
}

export const ImageGalleryCard: React.FC<ImageGalleryCardProps> = ({
  img,
  handleZoomClick,
  handleDeleteImageClick,
  hideDeleteButton,
  ...props
}: ImageGalleryCardProps) => {
  return (
    <ImageWrapper {...props}>
      <Image img={img} onClick={handleZoomClick} />
      {!hideDeleteButton && <ImageDeleteButton onClick={handleDeleteImageClick} />}
      <ImageZoomIcon />
    </ImageWrapper>
  );
};

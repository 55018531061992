import React, { useState, useEffect, useRef } from 'react';
import {
  Snackbar as MuiSnackbar,
  Alert as MuiAlert,
  AlertProps,
  useMediaQuery,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useStateDebounce } from '../../hooks';
import { MESSAGES } from '../../const';

let snack: any = {};

const theme = (isMobile: boolean) =>
  createTheme({
    components: {
      MuiSnackbar: {
        styleOverrides: {
          root: {
            minHeight: '4.8rem',
            width: '48rem',
            padding: 0,
            color: 'var(--white)',
            whiteSpace: 'break-spaces',

            '& .MuiSvgIcon-root': {
              width: '2.4rem',
              height: '2.4rem',
            },
            ...(isMobile && {
              width: 'initial',
              right: '2.4rem',
              bottom: '2.4rem',
              left: '2.4rem',
            }),
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr max-content',
            gridAutoFlow: 'column',
            gridColumnGap: '0.4rem',
            width: '100%',
            padding: '0.7rem',
            margin: 0,
            fontSize: '1.6rem',
            fontFamily: 'Inter',
            lineHeight: '1.5',
          },
          icon: {
            margin: 0,
            padding: '0.5rem',
          },
          message: {
            padding: '0.5rem 0',
          },
          action: {
            margin: 0,
            padding: 0,
          },
        },
      },
    },
  });

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />;
});
Alert.displayName = 'Alert';

type SnackbarVariants = 'default' | 'error' | 'info' | 'success' | 'warning';
const SnackbarBackgrounds = {
  info: 'var(--primary-90)',
  success: 'var(--green-90)',
  error: 'var(--red-90)',
  warning: 'var(--yellow-100)',
  default: 'var(--gray-90)',
};

export const Snackbar = () => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const snackbarRef = useRef<any>();
  const [open, setOpen] = useStateDebounce(false, 6000);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<SnackbarVariants>('default');

  useEffect(() => {
    snack.open = () => {
      if (message === MESSAGES.NETWORK.WARNING.TRY_AGAIN && open) return;

      setMessage(`${snack.message}`);
      setSeverity(snack.severity);
      setOpen(true, true);
      setOpen(false);
    };
  }, []);

  useEffect(() => {
    if (open) window.addEventListener('mousedown', handleOutsideClick);
    return () => window.removeEventListener('mousedown', handleOutsideClick);
  }, [open]);

  const handleOutsideClick = (e: Event) => {
    if (!snackbarRef.current.contains(e.target)) setOpen(false, true);
  };

  const handleClose = (e: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false, true);
  };

  return (
    <ThemeProvider theme={theme(isMobile)}>
      <MuiSnackbar ref={snackbarRef} open={open} onClose={handleClose}>
        <Alert
          style={{ backgroundColor: SnackbarBackgrounds[severity] }}
          severity={severity === 'default' ? 'info' : severity}
          onClose={handleClose}
        >
          {message}
        </Alert>
      </MuiSnackbar>
    </ThemeProvider>
  );
};

export const enqueueSnackbar = (message = '', severity: SnackbarVariants) => {
  if (!message || !snack.open) return;

  snack = {
    ...snack,
    message,
    severity,
  };
  snack.open();
};

export const useSnackbar = () => {
  return {
    enqueueSnackbar,
  };
};

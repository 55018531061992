export const MESSAGES = {
  AUTH: {
    SUCCESS: {
      LOG_OUT: 'Successfully logged out',
    },
    ERROR: {
      ACCOUNT_NOT_FOUND: 'Account does not exist',
      USER_NOT_FOUND: 'User does not exist',
      INVALID_TOKEN: 'Access denied: Invalid Token',
    },
  },
  CONTAINERS: {
    SUCCESS: {
      FILES_CHANGE: 'Changes made!',
    },
    ERROR: {
      FILES_CHANGE: 'Changes not made!',
    },
  },
  CONSTRUCTION: {
    HEADING: 'We will be back',
  },
  ERROR_BOUNDARY: {
    HEADING: 'Something went wrong',
    DEFAULT: 'Something went wrong',
    DETAIL: 'Probably, it is not your fault. Please come back later!',
  },
  NETWORK: {
    ERROR: 'Network error',
    WARNING: {
      TRY_AGAIN: 'Please try again (PRISMA DISCONNECTED)',
    },
  },
};

export const INPUT_ERRORS = {
  REQUIRED: {
    YARD_NAME: 'Yard Name is required',
    LICENSE_PLATE_NUMBER: 'License Plate Number is required',
    DATE: 'Date is required',
  },
  NOT_VALID: {
    EMAIL: 'Email is not valid',
    CONTAINER_NUMBER_SHORT: 'Not valid number',
    CONTAINER_NUMBER_MIN: 'Min 3 characters',
    COMMENT_LENGTH: 'Comment must have between 10 and 200 characters',
    LICENSE_PLATE_NUMBER: 'License Plate Number is not valid',
    LICENSE_PLATE_NUMBER_LENGTH: 'License Plate must have between 4 and 10 characters',
    DATE: 'Date is not valid',
  },
};

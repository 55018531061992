import axios from 'axios';
import { showToRoles } from '../utils';
import { User } from '../types';

class ClientYard {
  getYardsForClient = (data: any, user: User) => {
    let path = `/clients/${data.id}/yards`;
    if (showToRoles('client', user)) {
      path = '/client-yards/yards';
    }

    return axios
      .get(`${path}`, {
        params: data.params,
      })
      .then((res) => res.data.data);
  };

  assignedYards = (props: any, user: User) => async () => {
    if (!props.id) return [];

    const res = await this.getYardsForClient({ id: props.id, params: { notLoading: true } }, user);

    if (showToRoles('client', user))
      return res
        .filter((obj: any) => obj.status === 'ACTIVE')
        .map((obj: any) => ({ label: obj.yard.name, id: obj.yard.id }));

    return res.active.map((obj: any) => ({ label: obj.yard.name, id: obj.yard.id }));
  };
}

export const clientYardService = new ClientYard();

const maxSize = 10 * 1024 * 1024; // 10mb

export const filesSizeValidator = (files = {}, label = 'File') => {
  if (Object.values(files).every((file: any) => file.size <= maxSize)) return;

  return Object.values(files)
    .filter((file: any) => file.size > maxSize)
    .map((file: any) => `${label} "${file.name}" is too large`)
    .join('\n');
};

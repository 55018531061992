import React from 'react';
import { ErrorPage } from '../../pages';
import { useComponentDidCatch } from './use-component-did-catch';
import { MESSAGES } from '../../const';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError?: boolean;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps & ErrorBoundaryState> = ({
  children,
  ...props
}: ErrorBoundaryProps & ErrorBoundaryState) => {
  const [hasError, setHasError] = useComponentDidCatch(props?.hasError);

  const resetError = () => setHasError(false);

  if (hasError)
    return (
      <ErrorPage
        heading={MESSAGES.ERROR_BOUNDARY.HEADING}
        detail={MESSAGES.ERROR_BOUNDARY.DETAIL}
        navigateTo={-1}
        resetError={resetError}
      />
    );

  return <>{children}</>;
};

import { ActionType, Actions } from '../actions';
import { User } from '../../types';

export const user = (draft = {} as { data: User }, action: ActionType): { data: User } => {
  switch (action.type) {
    case Actions.SET_USER_DATA:
      draft.data = action.payload;
      return draft;
    default:
      return draft;
  }
};

import { initialUser } from '../../const';
import { User } from '../../types';

export interface UserState {
  data: User;
}

export interface GlobalState {
  user: UserState;
}

export const initialState = {
  user: { data: initialUser },
} as GlobalState;

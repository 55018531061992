import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

export const MobileHeightControl = () => {
  useEffect(() => {
    if (!isMobile) return;

    const element: any = document.querySelector('#root');
    element.style.height = `${window.innerHeight}px`;
  }, []);

  return null;
};

import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '../../const';

type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'destructive'
  | 'destructiveSecondary'
  | 'white'
  | 'dropdown-option'
  | 'dropdown-color-picker'
  | 'appbar-dropdown-header';
const themeColors = {
  primary: {
    main: colors['--primary-100'],
    dark: colors['--primary-80'],
  },
  secondary: {
    main: colors['--primary-40'],
    dark: colors['--primary-30'],
    contrastText: colors['--primary-100'],
  },
  destructive: {
    main: colors['--red-100'],
    dark: colors['--red-80'],
  },
  destructiveSecondary: {
    main: colors['--red-30'],
    dark: colors['--red-40'],
    contrastText: colors['--red-100'],
  },
  white: {
    main: colors['--primary-100'],
    dark: colors['--white'],
    contrastText: colors['--primary-100'],
  },
  'dropdown-option': {
    main: colors['--white'],
    dark: colors['--primary-40'],
    contrastText: colors['--primary-100'],
  },
  'dropdown-color-picker': {
    main: colors['--white'],
    dark: colors['--gray-40'],
    contrastText: colors['--gray-80'],
  },
  'appbar-dropdown-header': {
    main: colors['--white'],
    dark: colors['--gray-40'],
    contrastText: colors['--gray-80'],
  },
  grey: {
    main: colors['--white'],
    dark: colors['--gray-40'],
    contrastText: colors['--gray-80'],
  },
};

const theme = (color: ColorTypes, colorPicked: string | null = '') =>
  createTheme({
    palette: {
      primary: colorPicked
        ? {
            main: colorPicked,
            contrastText: colors['--gray-80'],
          }
        : themeColors[color],
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'initial',
            fontFamily: 'Inter',
            fontWeight: 400,
            '&[data-variant=outlined]': {
              backgroundColor: themeColors[color].dark,
            },
            '&[data-color=dropdown-option]': {
              minWidth: 'max-content',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.4rem var(--primary-40)',
            },
            '&[data-focus=true]:focus': {
              boxShadow: 'none',
            },
            '&.Mui-focusVisible[data-option-focus=true]': {
              backgroundColor: colors['--gray-40'],
            },
            '&:disabled': {
              color: 'var(--white)',
              backgroundColor: 'var(--gray-50)',
              borderColor: 'var(--gray-50)',
            },
            '& .MuiSvgIcon-root': {
              transition: 'transform 0.3s',
            },
            '& > .MuiSvgIcon-root': {
              position: 'absolute',
              fontSize: '2.4rem',
            },
            '&[data-open-select=true] .MuiButton-endIcon > svg': {
              transform: 'rotateZ(180deg)',
            },
            '&[data-color=appbar-dropdown-header] > .MuiButton-startIcon': {
              marginRight: '1.2rem',
            },
            '&[data-color=appbar-dropdown-header] > .MuiButton-endIcon': { marginLeft: '1.2rem' },
            '&[data-justify=left]': {
              justifySelf: 'left',
            },
            '&[data-justify=center]': {
              justifySelf: 'center',
            },
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          outlinedSizeLarge: {
            padding: '1.1rem 2.3rem',
          },
          outlinedSizeMedium: {
            padding: '0.7rem 1.9rem',
          },
          outlinedSizeSmall: {
            padding: '0.7rem 1.7rem',
          },
          sizeLarge: {
            padding: '1.2rem 2.4rem',
            minWidth: '4.8rem',
            height: '4.8rem',
            fontSize: '1.6rem',
            lineHeight: 1.5,
          },
          sizeMedium: {
            padding: '0.8rem 2rem',
            minWidth: '4rem',
            fontSize: '1.6rem',
            lineHeight: 1.5,

            '&[data-square=true]': {
              height: '4rem',
            },
          },
          sizeSmall: {
            padding: '0.8rem 1.8rem',
            minWidth: '3.6rem',
            fontSize: '1.4rem',
            lineHeight: '2rem',

            '& > .MuiSvgIcon-root': {
              fontSize: '2rem',
            },
            '&[data-square=true]': {
              height: '3.6rem',
            },
          },
          iconSizeLarge: {
            '& > *:nth-of-type(1)': {
              fontSize: '2.4rem',
            },
          },
        },
      },
    },
  });

enum defaultVariants {
  primary = 'contained',
  secondary = 'contained',
  destructive = 'contained',
  destructiveSecondary = 'contained',
  white = 'outlined',
  'dropdown-option' = 'contained',
  'dropdown-color-picker' = 'contained',
  'appbar-dropdown-header' = 'contained',
}

interface ButtonProps extends MuiButtonProps {
  'data-value'?: any;
  'data-color'?: ColorTypes;
  'data-color-picked'?: string;
  'data-open-select'?: boolean;
  children?: string | React.ReactNode | any;
  withoutFocus?: boolean;
  isSquare?: boolean;
  justify?: 'left' | 'center';
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  children,
  withoutFocus = false,
  isSquare,
  justify,
  ...props
}: ButtonProps) => {
  props['data-color'] = props['data-color'] || 'primary';
  const defaultVariant = defaultVariants[props['data-color']];

  return (
    <ThemeProvider theme={theme(props['data-color'], props['data-color-picked'])}>
      <MuiButton
        variant={variant || defaultVariant}
        data-focus={withoutFocus}
        data-option-focus={props['data-color'] === 'dropdown-option'}
        data-variant={defaultVariant}
        disableFocusRipple={props['data-color'] === 'dropdown-option'}
        data-square={isSquare}
        data-justify={justify}
        {...props}
      >
        {children}
      </MuiButton>
    </ThemeProvider>
  );
};

import { useQuery } from '../use-query';
import { extendContainerValidator } from '../../utils';
import { containersService } from '../../services';

export const useContainersQuery = (keys: any = {}) => {
  const containerNumber = keys.number;
  if (keys.number?.length < 3 || !extendContainerValidator(keys.number)) {
    delete keys.number;
  }

  const result = useQuery(
    ['container-options', JSON.stringify(keys)],
    () => containersService.options({ ...keys, notLoading: false })(),
    { initialData: [], keepPreviousData: true },
  );

  result.data = result.data.filter((obj: any) => obj.label.includes(containerNumber));

  return result;
};

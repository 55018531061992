import { useState, useRef, useEffect } from 'react';
import {
  Button,
  Input,
  ImageGalleryCardWrapper,
  ImageGalleryCard,
  Typography,
  ImageGallery,
  enqueueSnackbar,
  fullScreenButtonClick,
} from '../../design-system';
import {
  DashboardFormPopup,
  DashboardHeader,
  CloseIconButton,
  HideInput,
  DashbaordFormFooter,
} from './DashboardComponents';
import { useStore } from '../../context';
import { useUpdateImagesMutation } from '../../hooks';
import { filesFormatValidator, showToRoles } from '../../utils';
import { MESSAGES } from '../../const';

interface DashboardAdminFormProps {
  container: any;
  isOpen: boolean;
  refetch: () => void;
  handleFormCloseClick: () => void;
}

export const DashboardAdminForm: React.FC<DashboardAdminFormProps> = ({
  isOpen,
  refetch,
  container,
  handleFormCloseClick,
}: DashboardAdminFormProps) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const { mutate } = useUpdateImagesMutation({ refetch });
  const [oldImages, setOldImages] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [isDeleteOldImage, setIsDeleteOldImage] = useState<boolean>(false);
  const [galleryImages, setGalleryImages] = useState<any[]>([]);
  const [zoomImageIndex, setZoomImageIndex] = useState<number>(1);
  const deleteOldPhotos = useRef<any>([]);
  const inputRef = useRef<any>();

  useEffect(() => {
    if (!isOpen) {
      handleOnCancel();
      return;
    }

    setOldImages(container.images);
  }, [isOpen]);

  const handleOnCancel = () => {
    deleteOldPhotos.current = [];

    setOldImages([]);
    setImages([]);
    setFiles([]);
    setIsDeleteOldImage(false);
  };

  const handleZoomImageClick = (i: number) => {
    setGalleryImages([...oldImages.map(({ url }: any) => url), ...images]);
    setZoomImageIndex(i);
    fullScreenButtonClick();
  };

  const handleChangeFiles = (newImages: any, newFiles: any) => {
    const newImagesValues = [...images, ...newImages];

    if (filesFormatValidator(newFiles, 'Image')) {
      enqueueSnackbar(filesFormatValidator(newFiles, 'Image'), 'warning');
      return;
    }

    setImages(newImagesValues);
    setFiles([...files, ...newFiles]);

    inputRef.current.type = 'text';
    inputRef.current.type = 'file';
  };

  const handleDeleteImageClick = (i: number) => {
    if (i - oldImages.length >= 0) {
      i = i - oldImages.length;

      setImages(images.filter((_: any, index: number) => index !== i));
      setFiles(files.filter((_: any, index: number) => index !== i));
    } else {
      deleteOldPhotos.current = [
        ...deleteOldPhotos.current,
        { action: 'delete', name: oldImages[i].name },
      ];

      setOldImages(oldImages.filter((_: any, index: number) => index !== i));
      setIsDeleteOldImage(true);
    }
  };

  const handleSaveClick = () => {
    if (images.length > 10) {
      enqueueSnackbar('Max Upload Files is 10', 'warning');
      return;
    }

    const newPhotos = images.map((img: any, i: any) => ({
      action: 'upload',
      file: files[i],
    }));

    mutate(
      {
        id: container.id,
        photos: [...newPhotos, ...deleteOldPhotos.current],
      },
      {
        onSuccess: () => {
          refetch();
          handleFormCloseClick();
          enqueueSnackbar(MESSAGES.CONTAINERS.SUCCESS.FILES_CHANGE, 'success');
        },
        onError: () => {
          enqueueSnackbar(MESSAGES.CONTAINERS.ERROR.FILES_CHANGE, 'error');
        },
      },
    );
  };

  return (
    <DashboardFormPopup id={'dashboard'} isOpen={isOpen}>
      <ImageGallery photos={galleryImages} startIndex={zoomImageIndex} />
      <DashboardHeader>
        <Typography variant={'textXL'} weight={'bold'}>
          {container.number}: {container.clientCode}
        </Typography>
        <CloseIconButton onClick={handleFormCloseClick} />
      </DashboardHeader>
      <HideInput>
        <Input
          id={'images'}
          label={'Container Images'}
          data-size={'medium'}
          type={'file'}
          inputRef={inputRef}
          inputProps={{
            accept: '.jpeg, .jpg, .png, .webp',
            multiple: true,
          }}
          value={undefined}
          handleChangeFiles={handleChangeFiles}
        />
      </HideInput>
      <ImageGalleryCardWrapper>
        {[...oldImages, ...images].map((img: any, i: any) => {
          let isHideDeleteButton = false;
          if (oldImages.length > i && !showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user)) {
            isHideDeleteButton = true;
          }

          return (
            <ImageGalleryCard
              key={i}
              img={img.url || img}
              handleZoomClick={() => handleZoomImageClick(i)}
              handleDeleteImageClick={() => handleDeleteImageClick(i)}
              hideDeleteButton={isHideDeleteButton}
            />
          );
        })}
      </ImageGalleryCardWrapper>
      <DashbaordFormFooter>
        <Button size={'medium'} onClick={() => inputRef.current.click()}>
          Add Images
        </Button>
        <Button
          size={'medium'}
          data-color={'white'}
          onClick={handleOnCancel}
          disabled={!images.length && !isDeleteOldImage}
        >
          Cancel
        </Button>
        <Button
          size={'medium'}
          onClick={handleSaveClick}
          disabled={!images.length && !isDeleteOldImage}
        >
          Save
        </Button>
      </DashbaordFormFooter>
    </DashboardFormPopup>
  );
};

import { UserActions } from './user';
import { User } from '../../types';

export const Actions = {
  ...UserActions,
};
export type ActionsType = UserActions;

interface SetUserDataAction {
  type: UserActions.SET_USER_DATA;
  payload: User;
}

export type ActionType = SetUserDataAction;

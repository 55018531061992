import { Paper as MuiPaper, PaperProps as MuiPaperProps, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = (isMobile: boolean) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: '2.8rem',
            border: 'solid 0.1rem var(--gray-50)',
            borderRadius: '0.6rem',
            boxShadow: 'none',

            backgroundColor: 'var(--white)',

            '&.MuiPaper-root': {
              overflowY: 'overlay',
            },

            '&[data-position=absolute]': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxHeight: '70rem',
            },

            '&[data-size=default]': {
              width: '54rem',
            },
            '&[data-size=max-content]': {
              width: 'max-content',
              height: 'max-content',
            },
            '&[data-size=full-size]': {
              width: '100%',
              height: '100%',
            },
            ...(isMobile && {
              padding: 'none',
              border: 'none',
              backgroundColor: 'transparent',

              '&[data-size=default]': {
                width: '100%',
              },
            }),
          },
        },
      },
    },
  });

export type PaperSizeProps = 'default' | 'max-content' | 'full-size';

export interface PaperProps extends MuiPaperProps {
  paperSize?: PaperSizeProps;
  position?: 'absolute' | 'initial';
}

export const Paper: React.FC<PaperProps> = ({
  paperSize = 'default',
  position = 'initial',
  children,
  ...props
}: PaperProps) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme(isMobile)}>
      <MuiPaper data-size={paperSize} data-position={position} {...props}>
        {children}
      </MuiPaper>
    </ThemeProvider>
  );
};

import { useMutation } from 'react-query';
import { toIsoDate } from '../../utils';
import { gateOutsService, removeCache } from '../../services';

export const useAddGateOutMutation = () => {
  return useMutation(
    (props: any = {}) => {
      const otherProps: any = {};
      if (props.releaseNumber) otherProps.releaseNumber = props.releaseNumber;
      if (props.comment) otherProps.comment = props.comment;

      props = {
        containers: props.containerNumber.map((number: any, i: any) => ({
          number,
          id: props.containerId[i],
        })),
        authorizedLicensePlateNumbers: props.licensePlateNumber,
        validFrom: toIsoDate(
          props.validFrom.set({
            hour: 1,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
        ),
        validTo: toIsoDate(
          props.validTo.set({
            hour: 23,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
        ),
        clientId: props.clientId,
        yardId: props.yardId,
        ...otherProps,
      };

      return gateOutsService.add(props).then((res) => res.data);
    },
    {
      onSuccess: () => {
        removeCache(['key']);
      },
    },
  );
};

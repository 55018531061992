import { Close as CloseIcon, FilterAlt as FilterIcon } from '@mui/icons-material';
import styled, { css } from 'styled-components';
import { IconButton, Typography } from '../../design-system';

export const DashboardBase = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;

  height: 100%;
`;

export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-auto-rows: max-content;
  grid-row-gap: var(--spacing-6);

  height: 100%;
  padding: var(--spacing-6);
  position: relative;
  overflow: hidden;
`;

interface DashboardPopupProps {
  isOpen: boolean;
}

export const DashboardPopup = styled(DashboardWrapper)<DashboardPopupProps>`
  width: 100%;
  background-color: #f5f6fa;

  position: absolute;
  top: 0;
  right: -100%;
  transition: right 0.3s;
  z-index: 1000;

  ${(props) =>
    props.isOpen &&
    css`
      right: 0;
    `}
`;

export const DashboardFormPopup = styled(DashboardPopup)``;

export const DashboardHeader = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 6rem) max-content;
  grid-column-gap: var(--spacing-6);
  align-items: center;

  position: relative;
`;

export const DashboardInputs = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: var(--spacing-6);
`;

export const HideInput = styled.div`
  display: none;
`;

export const DashbaordFormFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-6);
  grid-row-gap: var(--spacing-6);
  margin-top: auto;

  & > *:nth-child(1) {
    grid-column: 1 / 3;
  }
`;

const DashboardIconButtonBase = styled(IconButton)`
  height: max-content;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

interface FilterIconButtonProps {
  onClick: () => void;
}

export const FilterIconButton: React.FC<FilterIconButtonProps> = ({
  onClick,
}: FilterIconButtonProps) => {
  return (
    <DashboardIconButtonBase onClick={onClick} iconColor={'--gray-90'}>
      <FilterIcon />
    </DashboardIconButtonBase>
  );
};

export const CloseIconButton: React.FC<FilterIconButtonProps> = ({
  onClick,
}: FilterIconButtonProps) => {
  return (
    <DashboardIconButtonBase onClick={onClick} iconColor={'--gray-90'}>
      <CloseIcon />
    </DashboardIconButtonBase>
  );
};

const DashboardTableWrapepr = styled.div`
  height: calc(100% - 8.8rem);
  position: absolute;
  top: 8.8rem;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--white);
  border-top: solid 0.1rem var(--gray-50);
  overflow: auto;
`;

const DashboardTableBase = styled.table`
  display: table;
  width: 100%;
  height: max-content;

  border-spacing: 0;
`;

export const DashboardTable = ({ children }: any) => {
  return (
    <DashboardTableWrapepr>
      <DashboardTableBase>{children}</DashboardTableBase>
    </DashboardTableWrapepr>
  );
};

const DashboardTableHeaderWrapper = styled.thead``;

const DashboardTableHeaderBase = styled.tr`
  display: table-row;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: var(--white);

  & > * {
    display: table-cell;
    padding: var(--spacing-3) 0;
    border-bottom: 0.1rem solid var(--gray-40);
  }

  & > *:first-child {
    padding-left: var(--spacing-6);
  }

  & > *:last-child {
    padding-right: var(--spacing-6);
  }

  & > *:not(:first-child) {
    padding-left: var(--spacing-2);
  }
`;

export const DashboardTableHeader = ({ children }: any) => {
  return (
    <DashboardTableHeaderWrapper>
      <DashboardTableHeaderBase>{children}</DashboardTableHeaderBase>
    </DashboardTableHeaderWrapper>
  );
};

export const DashboardTableRows = styled.tbody`
  display: table-row-group;
`;

export const DashboardTableRow = styled.tr`
  display: table-row;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--gray-40);
  }

  &:not(:nth-last-child(1)) > * {
    border-bottom: 0.1rem solid var(--gray-40);
  }

  & > * {
    display: table-cell;
    padding: var(--spacing-3) 0;
  }

  & > *:first-child {
    padding-left: var(--spacing-6);
  }

  & > *:last-child {
    padding-right: var(--spacing-6);
  }

  & > *:not(:first-child) {
    padding-left: var(--spacing-2);
  }
`;

const DashboardTableCellBase = styled.td``;

export const DashboardTableCell = ({ children, ...props }: any) => {
  return (
    <DashboardTableCellBase>
      <Typography variant={'textS'} color={'--gray-80'} {...props}>
        {children}
      </Typography>
    </DashboardTableCellBase>
  );
};

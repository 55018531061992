import { useState, useEffect } from 'react';

export const useComponentDidCatch = (
  defaultValue?: boolean,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [hasError, setHasError] = useState(defaultValue || false);

  useEffect(() => {
    const originalErrorHandler = window.onerror;

    const errorHandler = (message: any) => {
      setHasError(true);
      console.clear();

      if (originalErrorHandler) {
        console.log(message);
      }
    };

    window.onerror = errorHandler;

    return () => {
      window.onerror = originalErrorHandler;
    };
  }, []);

  return [hasError, setHasError];
};

import {
  ConstructionSection,
  ConstructionSectionIcon,
  SectionHeading,
} from './ConstructionComponents';
import { MESSAGES } from '../../const';

export const ConstructionPage: React.FC = () => {
  return (
    <>
      <ConstructionSection>
        <ConstructionSectionIcon />
        <SectionHeading align={'center'}>{MESSAGES.CONSTRUCTION.HEADING}</SectionHeading>
      </ConstructionSection>
    </>
  );
};

import { Check as CheckIcon } from '@mui/icons-material';
import { Button } from '../button';

const optionsSort = (options: Array<any>, componentProps: any) => {
  return options.sort((a, b) => {
    const isSelectedAreaA = componentProps.selectedAreasEqaul?.keys.includes(
      a[componentProps.selectedAreasEqaul.key],
    )
      ? 1
      : 0;
    const isSelectedAreaB = componentProps.selectedAreasEqaul?.keys.includes(
      b[componentProps.selectedAreasEqaul.key],
    )
      ? 1
      : 0;

    return isSelectedAreaB - isSelectedAreaA;
  });
};

const getProps = (option: any, filteredInputValue: any) => {
  let value = option;
  let label = option;
  let isFilteredOption = true;
  let isEmptyOption = false;

  if (typeof option !== 'string') {
    value = option.value;
    label = option.label;
  }

  if (
    !option.isEmpty &&
    filteredInputValue &&
    !label.toLowerCase().includes(filteredInputValue.toLowerCase())
  )
    isFilteredOption = false;

  isEmptyOption = (!label && !value) || option.isEmpty;
  if (option.withNewOption && label) isEmptyOption = false;

  return { value, label, isFilteredOption, isEmptyOption };
};

const isSelectedOption = (componentProps: any, option: any) => {
  let isSelected = componentProps.selectedAreasEqaul
    ? componentProps.selectedAreasEqaul.keys.includes(
        componentProps.selectedAreasEqaul.key
          ? option[componentProps.selectedAreasEqaul.key]
          : option,
      )
    : false;

  if (!isSelected) {
    isSelected = componentProps.value === option;
  }
  if (!componentProps.multiple && componentProps.selectedAreasEqaul) {
    isSelected =
      componentProps.selectedAreasEqaul?.keys[0] === option[componentProps.selectedAreasEqaul?.key];
  }

  return isSelected;
};

export const optionsToComponents = (
  options: Array<any>,
  componentProps: any,
  filteredInputValue?: any,
) => {
  if (!options.length) return null;
  if (componentProps.multiple) options = optionsSort(options, componentProps);

  return options.map((option, i) => {
    const { value, label, isFilteredOption, isEmptyOption } = getProps(option, filteredInputValue);
    const isSelectedArea = isSelectedOption(componentProps, option);

    if (!isFilteredOption) return <span key={i}></span>;

    return (
      <Button
        key={i}
        style={isEmptyOption ? { display: 'none' } : {}}
        size={'medium'}
        data-color={componentProps['data-color'] || 'dropdown-option'}
        value={value}
        onClick={() => {
          if (!componentProps.onSelect) return;
          if (option.id) {
            const optionValue = {
              ...option,
              value: value || label,
              id: option.id !== 'none' ? option.id : '',
            };
            if (componentProps.withClearButton && isSelectedArea)
              Object.keys(optionValue).forEach((key) => (optionValue[key] = ''));

            return componentProps.onSelect(optionValue);
          }

          let optionValue = value || label;
          if (componentProps.withClearButton && isSelectedArea) optionValue = '';

          componentProps.onSelect(optionValue);
        }}
        endIcon={componentProps.multiple && isSelectedArea ? <CheckIcon /> : null}
        disabled={!!option.disabled}
        data-aria-selected={isSelectedArea}
        data-disabled-aria-selected={!!componentProps.disabledAriaSelected}
      >
        {label}
      </Button>
    );
  });
};

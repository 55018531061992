export const rolesLabel: any = {
  SUPER_ADMIN: 'Super Admin',
  YARD_COMPANY_ADMIN: 'Yard Company Admin',
  YARD_COMPANY_EMPLOYEE: 'Yard Company Employee',
  YARD_MANAGER: 'Yard Manager',
  YARD_DISPATCHER: 'Yard Dispatcher',
  CLIENT_MANAGER: 'Client Manager',
  CLIENT_DISPATCHER: 'Client Dispatcher',
};

export const signinRolesLabel: any = {
  YardCompanyAdmin: 'YARD_COMPANY_ADMIN',
  YardCompanyEmployee: 'YARD_COMPANY_EMPLOYEE',
  YardManager: 'YARD_MANAGER',
  YardDispatcher: 'YARD_DISPATCHER',
  ClientManager: 'CLIENT_MANAGER',
  ClientDispatcher: 'CLIENT_DISPATCHER',
};
